

.maintitle{
    margin-right: 10px;
}

/*header
{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
} */
.nav_links
{
    list-style: none;
    margin-left: auto;
    
}

.nav_links li {
    display: inline-block;
    padding: 0px 20px;
    /* color: black; */
}

.nav_links li a {
    color: white;
    transition: all 0.3s ease 0s;
    text-decoration: none;
} 

.nav_links li button {
    font-size: 16px;
    color: white;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    border: none;
    background-color: inherit;
} 

.nav_links li a:hover,
.nav_links li button:hover {
    text-decoration: underline;
}


.nav_links li a:hover {
    transition: all 0.3s ease 0s;
    /* color: #0088a9; */
}


.margin-top
{
    margin-top: 50px;
}


