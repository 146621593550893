html, body {
    min-width: 600px;
}
.layout {
    /* top: 56px;
    left: 0px;
    width: 1980px;
    height: 201px;
   */
    background: #F0E9F3 0% 0% no-repeat padding-box;
    /* opacity: 1; */

}

#header {
    display: block;
    margin-left:auto;
    margin-right:auto;
    width:100%;
    box-sizing: border-box;
    max-width:1280px;
    
}

.titleContainer {
    display: block;
    margin-left:auto;
    margin-right:auto;
    width:100%;
    box-sizing: border-box;
    max-width:1280px;
    
}

.titlePadding {
    padding-top: 100px;
}

.subtitlePadding {
    padding-top:10px;
}

.title {
   
    font: normal normal normal 34px/42px 'Myriad W01', sans-serif;
    letter-spacing: 0.01px;
    color: #333333;
}
.subtitle {
  
    font: normal normal 600 34px/42px 'Myriad W01', sans-serif;
    letter-spacing: 0.01px;
    color: #333333;
}

#hompagetext {
    font: normal normal 300 40px/60px 'Myriad W01', sans-serif;
    position:absolute; 
    top:50%;
    left:20%;
    right:10%;
    color:white;
}

.npllogo {
    height: 30px;
    width: 30px;
    margin-top: 20px;
}
.caption {
    padding-left:15px;;
}
.footer {
    color: #333333;
    background-color: #F2F2F2;
    padding-bottom: 20px;
    bottom: 0;
    width: "100%"
}

.footerImage {
    top: 776px;
    left: 374px;
    width: 30px;
    height: 28px;
    padding-top:20px;
}
.footerHeader {
    padding-top:20px;
    font-size:0.83em;
    font-weight:bold;

}
.footerText {
    padding-top: 5px;
    font-size: 0.67em;
    font-weight: bold;

}

.gridFooter
{
    float:right;
    font-weight:bold;
    padding:20px;
    font-size:20px;
}

/* .MuiImageListItem-item {
    height: 100%;
    display: block;
    position: relative;
    z-index: 99999;
} */


.btnGrid {
    margin-top:-20px;
   
}

.middleContainer {
    min-height:500px;

}